.right {
  float: right;
  height: 100%;
}

.right .action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
}

.right .action > i {
  font-size: 18px;
  vertical-align: middle;
  color: #1890ff;
}

.right .action:hover {
  background: rgba(0, 0, 0, 0.025);
}

.right .account .avatar {
  margin: 18px 8px 18px 0;
  color: #1890ff;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: middle;
}

.icon {
  font-size: 18px;
  padding: 4px;
}

.noticeButton {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
}
.accountName {
  font-weight: 400;
}
