@import '~antd/dist/antd.css';

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-checkbox-group-item {
  min-width: 200px;
  margin-bottom: 4px;
}

sty .text-right {
  text-align: right;
  white-space: nowrap;
}
